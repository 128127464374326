<template>
  <div class="page margin-left margin-left-large padding-left layout-vertical">
    <div class="layout-horizontal layout-middle layout-inflexible">
      <div class="font-size-extra-large margin-right">
        <back-button @click="handleBackClick"></back-button>
      </div>
      <ys-input placeholder="搜索问卷" prefix-icon="el-icon-search" class="margin-vertical input-search" dark hollow
                v-model="search">
      </ys-input>
      <ys-button lighting class="margin-left-large" @click="handleAddClick">+ 添加问卷</ys-button>
    </div>
    <div class="layout-flexible" style="overflow: hidden;">
      <ys-infinite-scroll-view style="height: 100%;" @loadmore="loadMore">
        <div>
          <div class="information">
            <div class="font-size-large">
              <span>问卷列表</span>
              <span class="font-size-medium font-color-secondary">，共<span
                  class="font-color-primary">{{ total }}</span>份问卷</span>
            </div>
            <div class="font-color-secondary">您可以向客户发送问卷的方式收集用户数据</div>
          </div>
          <div class="margin-top-small margin-bottom-small">
            <div v-for="(questionnaire, index) in questionnaireList" :key="questionnaire.id"
                 class="list-item layout-horizontal layout-middle" @click="handleQuestionnaireClick(questionnaire)">
              <i class="fa fa-clipboard font-size-medium"></i>
              <div class="margin-left layout-flexible">
                <div class="font-size-medium">{{ questionnaire.title }}</div>
              </div>
              <div class="layout-inflexible">
                <ys-button round class="btn-delete margin-right-small" type="secondary"
                           @click.stop="handleQuestionnaireDeleteClick(index)">
                  <i class="far fa-trash-alt font-color-danger"></i>
                </ys-button>
              </div>
              <i class="fa fa-chevron-right font-color-placeholder"></i>
            </div>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <delete-dialog :visible.sync="deleteRemindDialogVisible" message="您确定要删除该问卷吗？"
                   @confirm="handleQuestionnaireDeleteConfirm"></delete-dialog>
  </div>
</template>

<script>

import YsInput from "@/components/wedigets/YsInput";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";
import BackButton from "@/components/backbutton/BackButton";
import DeleteDialog from "@/components/dialog/DeleteDialog";

function doGetQuestionnaire(id) {
  this.$reqGet({
    path: `/questionnaire/${id}`
  })
      .returnWhenCodeMatches()
      .then(res => {
        let questionnaire = res.data;
        this.$appendBefore(this.questionnaireList, [questionnaire]);
      })
      .catch(() => this.$message.error('加载失败'))
}

function doGetOrSearchQuestionnaireList(pageNum) {
  if (this.loadingCode == this.busyLoadingCode) return;
  this.busyLoadingCode = this.loadingCode;
  this.$reqGet({
    path: `/questionnaire/list/${this.organizationId}/${pageNum}/${this.pageSize}` + (this.search ? `/${encodeURIComponent(this.search)}` : ''),
  })
      .returnWhenCodeMatches()
      .then(res => {
        let questionnaireList = pageNum == 1 ? [] : this.questionnaireList;
        this.$appendAfter(questionnaireList, res.data.list);
        this.pageNum = res.data.pageNum;
        this.totalPages = res.data.pages;
        this.total = res.data.total;
        this.questionnaireList = questionnaireList;
      }).catch(() => {
    this.$message.error('加载失败');
  }).complete(() => {
    this.busyLoadingCode = 0;
  });
}

function doRemoveQuestionnaire(id) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqDelete({
    path: `/questionnaire/${id}`,
  })
      .returnWhenCodeMatches()
      .then(() => {
        this.$message.success('删除成功');
        for (let n = this.questionnaireList.length - 1; n >= 0; n--) {
          if (this.questionnaireList[n].id === id) {
            this.questionnaireList.splice(n, 1);
            break;
          }
        }
      }).catch(() => {
    this.$message.error('操作失败');
  }).complete(() => {
    this.busyLoadingCode = 0;
  })
}


export default {
  name: "QuestionnaireList",
  mixins: [httpapi],
  components: {DeleteDialog, BackButton, YsButton, YsInfiniteScrollView, YsInput},
  props: {
    organizationId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,
      search: null,
      questionnaireList: [],
      pageNum: 1,
      total: 0,
      totalPages: 0,
      pageSize: 20,
      clearList: false,
      searchingTimeoutId: null,

      currentQuestionnaireId: null,

      deleteQuestionnaireIndex: -1,
      deleteRemindDialogVisible: false,
    }
  },
  watch: {
    organizationId: {
      handler() {
        this.loadingCode++;
        this.currentQuestionnaireId = null;
        if (this.organizationId)
          this.loadQuestionnaireList();
      },
      immediate: true,
    },
    search: {
      handler() {
        if (this.searchingTimeoutId) clearTimeout(this.searchingTimeoutId);
        this.loadingCode++;
        this.pageNum = 1;
        this.totalPages = 0;
        this.clearList = true;
        this.searchingTimeoutId = setTimeout(() => {
          this.loadQuestionnaireList();
        }, 300);
      }
    }
  },
  mounted() {
    window.eventBus.$on('notify:questionnaire_add', this.handleQuestionnaireAdded);
  },
  activated() {
    if (this.currentQuestionnaireId) {
      this.loadQuestionnaire(this.currentQuestionnaireId);
      this.currentQuestionnaireId = null;
    }
  },
  methods: {
    loadQuestionnaireList(pageNum = 1) {
      doGetOrSearchQuestionnaireList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadQuestionnaireList(this.pageNum + 1);
      }
    },
    loadQuestionnaire: function (id) {
      doGetQuestionnaire.bind(this)(id);
    },
    removeQuestionnaire: function (id) {
      doRemoveQuestionnaire.bind(this)(id);
    },
    handleQuestionnaireClick(questionnaire) {
      this.currentQuestionnaireId = questionnaire.id;
      this.$router.push({name: 'questionnaire', query: {id: questionnaire.id}});
    },
    handleAddClick() {
      this.$router.push({name: 'questionnaire'});
    },
    handleQuestionnaireAdded(questionnaireId) {
      this.currentQuestionnaireId = questionnaireId;
    },
    handleQuestionnaireDeleteClick: function (index) {
      this.deleteQuestionnaireIndex = index;
      this.deleteRemindDialogVisible = true;
    },
    handleQuestionnaireDeleteConfirm: function () {
      this.removeQuestionnaire(this.questionnaireList[this.deleteQuestionnaireIndex].id);
    },
    handleBackClick() {
      this.$router.replace({name: 'users'});
    }
  },
  destroyed() {
    if (this.searchingTimeoutId) clearTimeout(this.searchingTimeoutId);
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
  box-sizing: border-box;
}

.input-search {
  width: 400px;
}

.list-item .btn-delete {
  visibility: hidden;
}

.list-item:hover .btn-delete {
  visibility: visible;
}

</style>